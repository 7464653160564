//
//
//
//

// Import necessary components and classes
import FlowForm, {
  QuestionModel,
  QuestionType,
  ChoiceOption,
  LanguageModel,
} from '@ditdot-dev/vue-flow-form'

export default {
  name: 'example',
  components: {
    FlowForm,
  },
  data() {
    return {
      language: new LanguageModel({
        // Your language definitions here (optional).
        // You can leave out this prop if you want to use the default definitions.
        enterKey: 'Enter',
        shiftKey: 'Shift',
        ok: 'Ok',
        continue: 'Continuar',
        skip: 'Pular',
        pressEnter: 'Pressione a tecla :enterKey',
        multipleChoiceHelpText: 'Escolha quantas quiser',
        multipleChoiceHelpTextSingle: 'Escolha apenas uma resposta',
        otherPrompt: 'Outra',
        placeholder: 'Escreva a sua resposta aqui...',
        submitText: 'Concluir',
        longTextHelpText: ':shiftKey + :enterKey para nova linha',
        prev: 'Anterior',
        next: 'Próxima',
        percentCompleted: ':percent% completo',
        invalidPrompt: 'Por favor, preencha o campo corretamente',
        thankYouText: 'Obrigado',
        successText: 'O seu formulário foi enviado com sucesso!',
        ariaOk: 'Pressione para continuar',
        ariaRequired: 'Esta etapa é obrigatória',
        ariaPrev: 'Etapa anterior',
        ariaNext: 'Próxima etapa',
        ariaSubmitText: 'Pressione para enviar',
        ariaMultipleChoice: 'Pressione :letter para selecionar',
        ariaTypeAnswer: 'Digite sua resposta aqui',
      }),
      questions: [
        // QuestionModel array
        new QuestionModel({
          title: 'O Will é bobo? Dica: 👍',
          required: true,
          type: QuestionType.MultipleChoice,
          options: [
            new ChoiceOption({
              label: 'Sim',
            }),
            new ChoiceOption({
              label: 'Mais que certo',
            }),
            new ChoiceOption({
              label: 'Não sei, mas sim',
            }),
          ],
        }),
        new QuestionModel({
          id: 'movies',
          title: 'Disserte por quê o Will é bobo. 😎🍿',
          type: QuestionType.LongText,
          required: true,
          placeholder: 'Digita aí que depois eu leio...',
        }),
      ],
    }
  },
  mounted() {
    window.matchMedia('(prefers-color-scheme: light)')
  },
}
